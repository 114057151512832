.admin-drivers {
  padding: 24px;

  .drivers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h1 {
      color: #333;
    }

    .add-driver-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      background: var(--click-color);
      color: white;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-size: 0.9rem;
      transition: background 0.2s;
    }
  }

  .drivers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 24px;

    .driver-card {
      background: white;
      border-radius: 8px;
      padding: 20px;
      position: relative;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .edit-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        background: none;
        border: none;
        color: #666;
        cursor: pointer;
        padding: 4px;
        border-radius: 4px;

        &:hover {
          background: #f5f5f5;
          color: #333;
        }
      }

      .driver-image {
        width: 80px;
        height: 80px;
        margin: 0 auto 16px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .driver-info {
        text-align: center;

        h3 {
          color: #333;
          margin-bottom: 8px;
        }

        .emp-id {
          color: #666;
          font-size: 0.9rem;
          margin-bottom: 4px;
        }

        .mobile {
          color: #888;
          font-size: 0.9rem;
        }
      }
    }
  }

  .driver-form {
    background: white;
    border-radius: 8px;
    padding: 24px;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
      color: #333;
      margin-bottom: 24px;
    }

    .form-group {
      margin-bottom: 16px;

      label {
        display: block;
        margin-bottom: 8px;
        color: #555;
        font-size: 0.9rem;
      }

      input {
        width: 100%;
        padding: 8px 12px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 0.9rem;

        &:focus {
          outline: none;
          border-color: #4caf50;
        }
      }
    }

    .form-actions {
      display: flex;
      gap: 12px;
      margin-top: 24px;

      button {
        flex: 1;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.9rem;
        transition: background 0.2s;

        &.cancel-btn {
          background: #f5f5f5;
          color: #666;

          &:hover {
            background: #e0e0e0;
          }
        }

        &.save-btn {
          background: var(--click-color);
          color: white;
        }
      }
    }
  }
}
