.testimonial {
    padding: 80px 0px;
    background-color: var(--theme-yellowbg);
    position: relative;
    margin-top: 60px;
    .word_slider {
      position: absolute;
      width: 100%;
      overflow: hidden;
      top: -45px;
      left: 0;
      .word_single {
        font-size: 100px;
        font-family: title_regular;
        margin-right: 65px;
        line-height: 90px;
        &.text-stroke {
          -webkit-text-stroke-color: var(--theme-yellow);
          -webkit-text-stroke-width: 1px;
          color: transparent;
        }
      }
    }
    .testimonial_card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      margin-top: 60px;
      .tc_single {
        box-shadow: 3px 3px 13px 0 rgba(0, 0, 0, 0.1);
        padding: 40px;
        background: var(--theme-white);
        position: relative;
        z-index: 1;
        border-radius: 8px;
        padding-bottom: 0px;
        .back_layer {
          background: #f8f8f8;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -20px;
          width: calc(100% - 50px);
          height: 100%;
          z-index: -1;
          box-shadow: 3px 3px 13px 0 rgba(0, 0, 0, 0.1);
          margin: auto;
          border-radius: 8px;
        }
  
        .top {
          display: flex;
          align-items: center;
          .left {
            width: 90px;
            height: 90px;
            margin-right: 30px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .right {
            h4 {
              font-size: 20px;
              font-family: "medium";
              color: var(--theme-darkgrey);
              margin-bottom: 6px;
              text-transform: capitalize;
            }
            h5 {
              font-size: 16px;
              font-family: regular;
              color: var(--theme-lightgrey);
              margin-bottom: 0;
            }
          }
        }
        .content {
          font-size: 16px;
          line-height: 30px;
          color: var(--theme-lightgrey);
        font-family: regular;
        margin-top: 18px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        }
        
      }
    }
  }
  