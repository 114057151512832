.home_services_div {
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    padding-bottom: -100px;
    margin: -60px 50px 70px 50px;
}

.home_service_mor_btn {
    margin: "30px 0px 45px 0px";
    color: var(--click-color);
    margin-top: 10px;
}

.home_video_section_parent {
    background-color: var(--white-color);
}

.home_video {
    border-radius: 0px 50px 50px 0px;
}

.home_faq_more_btnn {
    width: 50%;
    display: flex;
    justify-content: start;
}

.home_faq_Button {
    background-color: var(--click-color);
    /* width: 20%; */
    height: 40px;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
}

@media only screen and (max-width: 600px) {
    .home_services_div {
        padding-bottom: -100px;
        margin: 0px 15px 0px 15px;
    }

    .home_video {
        border-radius: 20px 20px 20px 0px;
    }

    .home_faq_more_btnn {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .home_faq_Button {
        margin-left: 0%;
        margin-top: 10px;
    }
}