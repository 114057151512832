.admin-orders-detail {
    padding: 24px;

    .admin-orders-detail-header {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        
        .admin-orders-detail-header-back-button {
            border: none;
            outline:none;
            color: white;
            background-color: var(--click-color);
            font-size: 1rem;
            padding: 8px 16px;
            cursor: pointer;
            transition: all 0.2s ease;
            border-radius: 4px;
            
            
            &:active {
                transform: translateY(1px);
            }
        }

        h1 {
            font-size: 1.5rem;
            font-weight: 500;
            color: #333;
            margin: 0;
        }
    }

    .section {
        padding: 20px;
        margin-bottom: 20px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .status-chip {
        margin-left: 8px;
    }

    .error {
        color: #f44336;
        padding: 20px;
        text-align: center;
    }

    .loading {
        text-align: center;
        padding: 20px;
    }

    .MuiTableContainer-root {
        margin-top: 16px;
    }
}
