.corporate_one {
  .banner {
    .slide_img {
      width: 100%;
      height: 520px;
      object-fit: cover;
    }
    .carousel-caption {
      right: 0px;
      left: 0px;
      bottom: inherit;
      color: #fff;
      z-index: 10;
      top: 25px;
      // top: 50%;
      // transform: translateY(-50%);
    }
    .banner_title {
      font-size: 70px;
      font-family: bold;
      max-width: 810px;
      margin: auto;
    }
    .banner_subtitle {
      font-size: 20px;
      font-family: regular;
    }
  }
}
