@media only screen and (min-width: 768px) {
    .mlauto {
        margin-left: auto;
    }
}

.more-expand-div {
    padding: 0px 0px;
}

.more_display_mode_card {
    justify-content: space-between;
}

.more-expand-div .pp_card {
    max-width: 500px;
    width: 100%;
}

.more-div-big-icon-div {
    border-radius: 8px;
    /* box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2); */
    /* margin: 0 10px; */
    background: var(--card-bg);
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 1023px;
    width: 100%;
    margin: 0 auto;
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    margin-bottom: 5px;
}

.more-div-big-icon-div .more-div-big-icon-div-inner {
    width: 33.3% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.more-div-big-icon-div .more-div-big-icon-div-inner div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--buttonfg-color);
}

.more-div-big-icon-div .more-div-big-icon-div-inner div span {
    color: var(--black-color);
    font-size: 2rem;
    transition: 0.5s;
    font-variation-settings:
        'FILL' 0
}

.more-div-big-icon-div .more-div-big-icon-div-inner:hover div {
    background: var(--click-color);
}

.more-div-big-icon-div .more-div-big-icon-div-inner:hover div span {
    color: var(--white-color);
    font-variation-settings:
        'FILL' 1
}

.more-div-big-icon-div .more-div-big-icon-div-inner h1 {
    font-size: 0.9rem;
    font-weight: bolder;
    padding-top: 10px;
}

.more-div-card {
    /* border: 1px solid #ddd; */
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.more-div-card .more-div-card-heading {
    font-size: 1.4rem;
    font-weight: bolder;
    padding-bottom: 8px;
}

.more-div-card-inner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    background: #f4f4f4;
    /* background: var(--cream-color); */
    border-radius: 4px;
    padding-left: 10px;
    margin: 10px 0;
}

.more-div-card-inner .more-div-card-inner-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(84, 204, 203, 0.5);
    /* background: var(--peach-color); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-div-card-inner .more-div-card-inner-icon span {
    color: #222;
    /* color: var(--cream-color); */
    transition: 0.5s;
    font-size: 1.3rem;
    font-variation-settings:
        'FILL' 0
}

.more-div-card-inner:hover .more-div-card-inner-icon span {
    font-variation-settings:
        'FILL' 1
}

.more-div-card-inner .more-div-card-inner-content {
    width: calc(100% - 40px);
    padding: 0 20px;
}

.more-div-card-inner .more-div-card-inner-content h2 {
    font-size: 1rem;
    color: #444;
    font-weight: bolder;
    padding-bottom: 3px;
}

.more-div-card-inner .more-div-card-inner-content h3 {
    font-size: 0.7rem;
    color: #aaa;
    font-weight: bolder;
}

.more-div-card-inner .more-div-card-inner-arrow {
    position: absolute;
    top: 55%;
    right: 5px;
    transform: translateY(-50%);
}

.more-div-card-inner .more-div-card-inner-arrow span {
    font-size: 1.2rem;
    color: var(--lightblue-color);
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .mauto {
        margin-left: auto;
        margin-right: auto;
    }

    .more-div-big-icon-div {
        margin-top: 0px;
        margin-bottom: -30px;
    }
}