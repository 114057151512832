
.corporate_one h1,
.corporate_one h2,
.corporate_one h3,
.corporate_one h4,
.corporate_one h5,
.corporate_one h6 {
  margin: 0;
}

.corporate_one p,
.corporate_one ul,
.corporate_one li,
.corporate_one a {
  margin: 0;
  text-decoration: none;
}

.relative {
  position: relative;
}

.br50{
  border-radius: 50%;
}
/*theme btn css start  */
.theme_btn {
  display: inline-block;
  padding: 12px 30px;
  /* background-color: var(--theme-orange); */
  /* background: linear-gradient(to right, var(--theme-orange), var(--theme-blue));  */
  background-color: var(--theme-teal);
  color: var(--white-color);
  font-size: 16px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid transparent;
}
.theme_btn.inline_btn{
  padding: 6px 12px;
}
.theme_btn.btn_white {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background: var(--white-color);
  color: var(--text-black);
}
.editable_text {
  font-size: 14px;
  display: flex;
  color: var(--theme-teal);
  cursor: pointer;
}
.editable_text .material-symbols-outlined{
  font-size: 14px;
  font-variation-settings:  
  'wght' 300,
  
}

.theme_btn.btn_white:hover {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background: transparent;
  border-color: var(--white-color);
  color: var(--white-color);
}
.theme_btn.btn_border {
  background: transparent;
  color: var(--theme-orange);
  border: 1px solid;
}

.theme_btn.btn_white_border:hover {
  border-color: transparent;
  background: var(--white-color);
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: var(--text-black);
}

.theme_btn.btn_white_border {
  border-color: var(--white-color);
  background: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.theme_btn:hover {
  background-color: var(--theme-midnight-blue);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.theme_btn.with_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.theme_btn.with_icon .btn_icon {
  font-size: 20px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.theme_btn.with_icon:hover .btn_icon {
  transform: translateX(10px);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
/*theme btn css end */

.sect_padding {
  padding: 100px 0px;
}
.pg_margin {
  padding: 30px 0px; 
}
.pg_bg{
  background: var(--theme-pg-bg);
}
.page_height {
  position: relative;
  /* min-height: calc(100vh - 166px); */
  min-height: 100vh;
  padding-bottom: 140px;
  padding-top: 113px;
}

.card_shadow{
  /* box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09); */
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14); */
  /* box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);    */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.card_padding{
  padding: 22px;
}
.card_bg{
  background: var(--white-color);
}
.card_bottom_border {
  border-bottom: 1px solid var(--theme-blue);
}
.click_text {
  font-size: 14px;
  color: var(--theme-blue);
}
.ct_border{
  text-decoration: underline !important;
}
.small_title_with_line {
  font-size: 16px;
  color: var(--theme-orange);
  text-transform: uppercase;
  position: relative;
  margin-left: 37px !important;
  font-family: semibold;
}

.small_title_with_line.text_white {
  color: var(--white-color);
}

.small_title_with_line:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 30px;
  background-color: var(--theme-orange);
  left: -35px;
  top: 50%;
  transform: translateY(-50%)
}

.small_title_with_line.text_white:after {
  background-color: var(--white-color);
}

/* text size css start  */
.df45 {
  font-size: 45px;
}

.df36 {
  font-size: 36px;
}

.df25 {
  font-size: 25px;
}

.df20 {
  font-size: 20px;
}

.df15 {
  font-size: 15px;
}
.df14 {
  font-size: 15px;
}
.df16 {
  font-size: 16px;
}

.lh26 {
  line-height: 26px;
}

/* vertical css  */
.dvg22{
  height: 22px;
}
.dvg12{
  height: 12px;
}

/* text size css end */
.pointer{
  cursor: pointer;
}
.back_with_text{
  display: flex;
  align-items: center;
  gap: 5px;
}
/* common css  */