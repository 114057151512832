.cart2-page {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 2rem;
  background-color: #f8f9fa;
  position: relative;

  .address-list-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .address-list-modal {
      background-color: white;
      border-radius: 8px;
      width: 90%;
      max-width: 500px;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .address-list-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #e0e0e0;

        h2 {
          margin: 0;
          font-size: 1.2rem;
        }

        span {
          cursor: pointer;
        }
      }

      .address-list-modal-body {
        overflow-y: auto;
        padding: 16px;

        .address-list-modal-body-single-address {
          background-color: #e0e0e0;
          border-radius: 5px;
          padding: 12px;
          margin-bottom: 12px;
          cursor: pointer;

          .address-list-modal-body-single-address-type {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            span {
              font-size: 1rem;
              margin-right: 8px;
              color: black;
            }
          }

          .address-list-modal-body-single-address-details {
            h4 {
              font-size: 1rem;
              margin: 0 0 4px 0;
            }

            p {
              margin: 0;
              font-size: 0.9rem;
              color: #666;
            }
          }
        }

        .selected-address {
          border: 3px solid var(--click-color);
          background-color: rgb(249, 218, 253);
        }
      }

      .address-list-modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border-top: 1px solid #e0e0e0;

        button {
          padding: 8px 16px;
          margin-left: 8px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.3s ease;

          &:first-child {
            background-color: #f0f0f0;
            color: #333;

            &:hover {
              background-color: #e0e0e0;
            }
          }

          &:last-child {
            background-color: var(--click-color);
            color: white;
          }
        }

        div {
          button {
            padding: 8px 16px;
            margin-left: 8px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.3s ease;

            &:first-child {
              background-color: #f0f0f0;
              color: #333;

              &:hover {
                background-color: #e0e0e0;
              }
            }

            &:last-child {
              background-color: var(--click-color);
              color: white;
            }
          }
        }
      }
    }
  }

  .cart2-info {
    flex: 2;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;

    .cart2-details {
      .cart2-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #333;
        }
      }

      .cart2-items {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .cart2-item-header {
          display: flex;
          padding: 1rem 0;
          border-bottom: 2px solid #eee;
          font-weight: 600;
          color: #333;

          span {
            flex: 1;
            text-align: center;

            &.cart2-item-product {
              flex: 2;
              text-align: left;
            }
          }
        }

        .cart2-item {
          display: flex;
          align-items: center;
          padding: 1.5rem 0;
          border-bottom: 1px solid #eee;

          &:last-child {
            border-bottom: none;
          }

          > div {
            flex: 2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          .cart2-item-product {
            flex: 2;
            justify-content: flex-start;

            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              border-radius: 8px;
              margin-right: 1.5rem;
            }

            .cart2-item-detail {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;

              h3 {
                font-size: 1.2rem;
                color: #333;
              }

              .cart-2-item-variant-info {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .cart-2-item-variant-name {
                  font-size: 1rem;
                }

                div {
                  display: flex;
                  gap: 10px;

                  .cart-2-item-variant-info-color {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    span {
                      font-size: 0.95rem;
                      color: #666;
                    }
                  }
                }

                .cart-2-item-variant-info-size {
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  span {
                    font-size: 0.95rem;
                    color: #666;
                  }
                }
              }

              .cart2-item-misc-functionality {
                display: flex;

                .cart2-item-sfl {
                  display: flex;
                  align-items: center;
                  margin-right: 1rem;
                  color: #666;
                  cursor: pointer;
                  transition: color 0.1s;
                  font-weight: 500;

                  &:hover {
                    color: var(--click-color);
                  }
                }

                .cart2-item-remove {
                  display: flex;
                  align-items: center;
                  margin-right: 1rem;
                  color: #666;
                  cursor: pointer;
                  font-weight: 500;
                  transition: color 0.1s;

                  &:hover {
                    color: red;
                  }
                }
              }
            }
          }

          .cart2-item-price-quantity-total {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .cart2-item-price,
          .cart2-item-itemTotal {
            h5 {
              font-size: 1.1rem;
              color: #666;
            }
          }

          .cart2-item-quantityhandler {
            display: flex;
            align-items: center;

            button {
              background-color: #f0f0f0;
              border: none;
              width: 30px;
              height: 30px;
              font-size: 1.2rem;
              cursor: pointer;
              transition: background-color 0.3s;

              &:hover {
                background-color: #e0e0e0;
              }
            }

            span {
              margin: 0 10px;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .cart2-summary-secondary {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .cart2-summary {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      // max-height: 400px;

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        color: #333;
      }

      .cart2-summary-details {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h5 {
          font-size: 1.15rem;
        }

        .cart2-summary-order-total,
        .cart2-summary-order-shipping,
        .cart2-summary-order-discount {
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
          color: #666;
        }
      }

      .cart2-summary-hr {
        border: none;
        border-top: 1px solid #747474;
      }

      .cart2-summary-final-payment-detail {
        .cart2-summary-final-amount {
          display: flex;
          justify-content: space-between;
          font-size: 1.2rem;
          font-weight: 600;
          margin: 10px 0;
        }

        
      }

      .cart2-payment-method {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 12px;
  
        h4 {
          font-size: 0.95rem;
          color: #333;
        }
  
        .cart2-payment-method-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          margin: 20px 0;
  
          .cart2-payment-method-single {
            flex: 1;
            display: flex;
            height: 50px;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            cursor: pointer;

            span{
              font-size: 0.95rem;
            }
          }

          .selected-payment-method {
            border: 3px solid var(--click-color);
            font-weight: 600;
            color: var(--click-color);
          }
        }
      }

      button {
        width: 100%;
        padding: 1rem;
        background-color: var(--click-color);
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s;

        .cart2-summary-final-amount-loading {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .cart2-summary-final-amount-loading-spinner {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #fff;
            border-top: 2px solid var(--click-color);
            animation: spin 1s linear infinite;
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        &:hover {
          background-color: var(--click-color);
        }
      }

      .cart2-summary-online-ptp {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }

      .disabled {
        cursor: not-allowed;
        background-color: red;
      }

      .cart2-summary-promocode {
        span {
          display: block;
          margin-bottom: 0.5rem;
          color: #666;
        }

        .cart2-promocode-form {
          display: flex;

          input {
            flex: 1;
            padding: 0.5rem;
            border: 1px solid #ddd;
            border-radius: 4px 0 0 4px;
            font-size: 1rem;
          }

          button {
            padding: 0.5rem 1rem;
            background-color: #28a745;
            color: #fff;
            border: none;
            border-radius: 0 4px 4px 0;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
              background-color: #218838;
            }
          }
        }
      }
    }

    

    .cart2-address-details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 10px;

      .cart2-address-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        h2 {
          font-size: 1.25rem;
          color: #333;
        }

        button {
          padding: 8px 16px;
          background-color: var(--click-color);
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.3s ease;
        }
      }

      .cart2-address-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .cart2-single-address {
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          padding: 12px;
          min-width: 100%;

          .cart2-single-address-type {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            span {
              margin-right: 8px;
              color: var(--click-color);
              font-weight: 600;
            }
          }

          .cart2-single-address-details {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .cart2-single-address-info {
              flex: 1;

              h4 {
                font-size: 1.15rem;
                margin-bottom: 5px;
                color: var(--click-color);
                font-weight: 600;
              }

              p {
                margin: 0;
                font-size: 0.9rem;
                color: #666;
              }
            }

            .material-symbols-outlined {
              cursor: pointer;
              color: #007bff;
            }
          }
        }

        .add-new-address {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          button {
            padding: 5px 10px;
            border-radius: 5px;
            background-color: var(--click-color);
            color: white;
            border: none;
            outline: none;
          }
        }

        .selected-address {
          background-color: rgb(252, 231, 255);
          border: 3px solid var(--click-color);
        }
      }
    }
  }
}

.cart2-page-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 35vh;
  gap: 20px;

  h2 {
    font-size: 2rem;
    color: #333;
  }

  button {
    padding: 10px 20px;
    background-color: var(--click-color);
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
}

// Media Queries (nested at the end of .cart2-page)
@media screen and (max-width: 1440px) {
  .cart2-page {
    padding: 1.5rem;
    gap: 15px;

    .cart2-info {
      padding: 1.5rem;

      .cart2-details {
        .cart2-header h2 {
          font-size: 1.3rem;
        }

        .cart2-item {
          padding: 1.2rem 0;

          .cart2-item-product {
            img {
              width: 70px;
              height: 70px;
            }

            .cart2-item-detail h3 {
              font-size: 1.1rem;
            }
          }
        }
      }
    }

    .cart2-summary-secondary {
      gap: 15px;

      .cart2-summary {
        padding: 1.5rem;
        h2 {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .cart2-page {
    padding: 1rem;
    gap: 12px;

    .cart2-info {
      padding: 1.2rem;

      .cart2-details {
        .cart2-header h2 {
          font-size: 1.2rem;
        }

        .cart2-item {
          padding: 1rem 0;

          .cart2-item-product {
            img {
              width: 60px;
              height: 60px;
              margin-right: 1rem;
            }
          }
        }
      }
    }

    .cart2-summary-secondary {
      gap: 12px;

      .cart2-summary {
        padding: 1.2rem;
        max-height: 380px;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .cart2-page {
    flex-direction: column;
    padding: 0.8rem;
    gap: 15px;

    .cart2-info {
      width: 100%;
      padding: 1rem;

      .cart2-details {
        .cart2-header h2 {
          font-size: 1.1rem;
        }

        .cart2-item {
          padding: 1.2rem 0;

          .cart2-item-product {
            img {
              width: 70px;
              height: 70px;
              margin-right: 1rem;
            }

            .cart2-item-detail {
              h3 {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
    }

    .cart2-summary-secondary {
      width: 100%;

      .cart2-summary {
        max-height: none;
        padding: 1.2rem;

        h2 {
          font-size: 1.2rem;
        }
      }

      .cart2-payment-method {
        padding: 1.2rem;

        .cart2-payment-method-list {
          flex-direction: column;

          .cart2-payment-method-single-cod,
          .cart2-payment-method-single-online {
            width: 100%;
            height: 80px;
          }
        }
      }

      .cart2-address-details {
        padding: 1.2rem;

        .cart2-address-header {
          h2 {
            font-size: 1.2rem;
          }

          button {
            padding: 10px 20px;
          }
        }

        .cart2-address-list {
          .cart2-single-address {
            padding: 15px;

            .cart2-single-address-details {
              h4 {
                font-size: 1.1rem;
              }

              p {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .cart2-page {
    padding: 8px;
    gap: 12px;

    .cart2-info {
      padding: 12px;

      .cart2-details {
        .cart2-items {
          gap: 12px;

          .cart2-item {
            flex-direction: column;
            padding: 16px;
            background: white;
            border-radius: 12px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
            position: relative;

            .cart2-item-product {
              width: 100%;
              margin-bottom: 16px;

              img {
                width: 80px;
                height: 80px;
                border-radius: 8px;
                margin-right: 16px;
                object-fit: cover;
              }

              .cart2-item-detail {
                h3 {
                  font-size: 1rem;
                  margin-bottom: 8px;
                }

                .cart-2-item-variant-info {
                  background: #f8f9fa;
                  padding: 8px;
                  border-radius: 8px;
                  font-size: 0.9rem;

                  .cart-2-item-variant-name {
                    color: #666;
                  }
                }

                .cart2-item-misc-functionality {
                  margin-top: 12px;
                  gap: 12px;

                  .cart2-item-sfl,
                  .cart2-item-remove {
                    font-size: 0.85rem;
                    padding: 6px 12px;
                    border-radius: 6px;
                    background: #f8f9fa;

                    span {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }

            .cart2-item-actions {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding-top: 12px;
              border-top: 1px solid #eee;

              .cart2-item-price {
                h5 {
                  font-size: 1rem;
                  color: var(--click-color);
                  font-weight: 600;
                }
              }

              .cart2-item-quantityhandler {
                button {
                  width: 28px;
                  height: 28px;
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }

    .cart2-summary-secondary {
      .cart2-summary,
      .cart2-address-details,
      .cart2-payment-method {
        padding: 16px;
        border-radius: 12px;

        h2 {
          font-size: 1.1rem;
          margin-bottom: 12px;
        }
      }

      .cart2-address-details {
        .cart2-address-header {
          button {
            padding: 8px 12px;
            font-size: 0.9rem;
          }
        }

        .cart2-single-address {
          padding: 12px;

          .cart2-single-address-info {
            h4 {
              font-size: 1rem;
            }
            p {
              font-size: 0.85rem;
              line-height: 1.4;
            }
          }
        }
      }

      .cart2-payment-method {
        .cart2-payment-method-list {
          gap: 12px;

          .cart2-payment-method-single-cod,
          .cart2-payment-method-single-online {
            padding: 12px;
            height: auto;
            font-size: 0.9rem;

            span.material-symbols-outlined {
              font-size: 1.2rem;
            }
          }
        }
      }

      .cart2-summary {
        .cart2-summary-details {
          gap: 12px;
          font-size: 0.9rem;

          h5 {
            font-size: 0.95rem;
          }
        }

        .cart2-summary-final-payment-detail {
          .cart2-summary-final-amount {
            font-size: 1rem;
            margin-bottom: 16px;
          }

          button {
            padding: 12px;
            font-size: 0.95rem;
          }
        }
      }
    }
  }

  // Modal styles for mobile
  .address-list-modal {
    width: 95% !important;
    max-height: 90vh !important;

    .address-list-modal-header {
      padding: 12px;
      h2 {
        font-size: 1.1rem;
      }
    }

    .address-list-modal-body {
      padding: 12px;

      .address-list-modal-body-single-address {
        padding: 12px;
        font-size: 0.9rem;

        .address-list-modal-body-single-address-details {
          h4 {
            font-size: 1rem;
          }
          p {
            font-size: 0.85rem;
            line-height: 1.4;
          }
        }
      }
    }

    .address-list-modal-footer {
      padding: 12px;

      button {
        padding: 8px 12px;
        font-size: 0.9rem;
      }
    }
  }

  .cart2-info {
    .cart2-details {
      .cart2-items {
        .MuiAccordion-root {
          margin: 8px 0 !important;
          border-radius: 8px !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;

          .MuiAccordionSummary-root {
            min-height: 48px !important;
            padding: 0 12px !important;
            background-color: #f8f9fa !important;
            border-radius: 8px !important;

            .MuiAccordionSummary-content {
              margin: 8px 0 !important;
              gap: 8px !important;

              .MuiTypography-h6 {
                font-size: 0.95rem !important;

                &:first-of-type {
                  // Category name
                  color: #333 !important;
                  font-weight: 600 !important;
                }

                &:last-of-type {
                  // Price
                  font-size: 0.9rem !important;
                  color: var(--click-color) !important;
                }
              }
            }

            .MuiAccordionSummary-expandIconWrapper {
              color: #666 !important;
              font-size: 1.2rem !important;
            }
          }

          .MuiAccordionDetails-root {
            padding: 8px !important;

            .cart2-item {
              margin-bottom: 8px !important;

              &:last-child {
                margin-bottom: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .cart2-page {
    padding: 0.3rem;

    .cart2-info {
      padding: 0.5rem;

      .cart2-details {
        .cart2-item {
          padding: 0.8rem;

          .cart2-item-product {
            img {
              width: 70px;
              height: 70px;
            }

            .cart2-item-detail {
              h3 {
                font-size: 0.95rem;
              }

              .cart-2-item-variant-info {
                padding: 6px;
                font-size: 0.85rem;
              }
            }
          }

          .cart2-item-actions {
            flex-direction: column;
            gap: 10px;

            .cart2-item-price-quantity {
              width: 100%;
              justify-content: space-between;
              padding: 6px 10px;
            }

            .cart2-item-misc-functionality {
              width: 100%;
              justify-content: space-between;
              margin-left: 0;

              .cart2-item-sfl,
              .cart2-item-remove {
                padding: 6px 10px;
                font-size: 0.8rem;
              }
            }
          }

          .cart2-item-total {
            font-size: 0.8rem;
            padding: 3px 8px;
          }
        }
      }
    }

    .cart2-summary-secondary {
      .cart2-summary {
        padding: 0.8rem;

        h2 {
          font-size: 1.1rem;
        }

        .cart2-summary-details {
          gap: 15px;

          h5 {
            font-size: 1rem;
          }
        }
      }

      .cart2-address-details,
      .cart2-payment-method {
        padding: 0.8rem;

        h2 {
          font-size: 1.1rem;
        }
      }
    }
  }
}
