.corporateTwo-footer {
  min-height: 50vh;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .corporateTwo-footer-upper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .corporateTwo-footer-upper-left {
      flex: 0.5;
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        font-size: 1rem;
      }

      .corporateTwo-footer-upper-left-iso {
        display: flex;
        justify-content: flex-start;
        gap: 20px;

        h5 {
          font-size: 0.75rem;
          color: #000;
        }
      }

      .corporateTwo-footer-upper-left-socials {
        display: flex;
        gap: 30px;
      }
    }

    .corporateTwo-footer-upper-middle {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 30%;

      .corporateTwo-footer-contact-info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .corporateTwo-footer-contact-item-cm {
          display: flex;
          align-items: flex-start;
          gap: 10px;

          a {
            display: flex;
            align-items: flex-start;
            gap: 10px;

            .corporateTwo-footer-contact-item-info{
              color: #000;
            }

            span:nth-child(1) {
              height: 30px;
              width: 30px;
              padding: 10px;
              border-radius: 50%;
              background-color: var(--logo-primary-color);
              color: #000;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    .corporateTwo-footer-upper-right {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .corporateTwo-footer-upper-right-links {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .corporateTwo-footer-divider {
    margin: 40px 0;
  }

  .corporateTwo-footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .corporateTwo-footer-bottom-left {
      h3 {
        font-size: 1.25rem;
        font-weight: 300;
      }
    }

    .corporateTwo-footer-bottom-right {
      h4 {
        font-weight: 300;
        font-size: 1.15rem;
      }
    }
  }
}

// Media Queries for responsiveness

@media (max-width: 1440px) {
  .corporateTwo-footer {
    padding: 30px 30px;

    .corporateTwo-footer-upper {
      .corporateTwo-footer-upper-left {
        .corporateTwo-footer-upper-left-socials {
          gap: 20px;
        }
      }

      .corporateTwo-footer-upper-middle {
        width: 35%;
      }

      .corporateTwo-footer-upper-right {
        .corporateTwo-footer-upper-right-links {
          gap: 15px;
        }
      }
    }

    .corporateTwo-footer-bottom {
      .corporateTwo-footer-bottom-left {
        h3 {
          font-size: 1.1rem;
        }
      }

      .corporateTwo-footer-bottom-right {
        h4 {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .corporateTwo-footer {
    padding: 30px 20px;
    margin-bottom: 40px;

    .corporateTwo-footer-upper {
      flex-direction: column;
      row-gap: 30px;

      .corporateTwo-footer-upper-left {
        flex: 1;
        gap: 30px;
        .corporateTwo-footer-upper-left-title {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .corporateTwo-footer-upper-left-socials {
          gap: 15px;
        }
      }

      .corporateTwo-footer-upper-middle {
        width: 100%;
      }

      .corporateTwo-footer-upper-right {
        margin-top: 20px;

        .corporateTwo-footer-upper-right-links {
          gap: 10px;
        }
      }
    }

    .corporateTwo-footer-bottom {
      flex-direction: column;
      align-items: center;

      .corporateTwo-footer-bottom-left,
      .corporateTwo-footer-bottom-right {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .corporateTwo-footer {
    height: auto;
    padding: 40px 15px;
    margin-bottom: 20px;

    .corporateTwo-footer-upper {
      .corporateTwo-footer-upper-left {
        .corporateTwo-footer-upper-left-socials {
          gap: 10px;
        }
      }

      .corporateTwo-footer-upper-middle,
      .corporateTwo-footer-upper-right {
        width: 100%;
      }

      .corporateTwo-footer-upper-right {
        margin-top: 15px;
      }
    }

    .corporateTwo-footer-bottom {
      flex-direction: column;
      align-items: flex-start;

      .corporateTwo-footer-bottom-left h3 {
        font-size: 1rem;
      }

      .corporateTwo-footer-bottom-right h4 {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .corporateTwo-footer {
    padding: 30px 10px;

    .corporateTwo-footer-upper {
      .corporateTwo-footer-upper-left {
        .corporateTwo-footer-upper-left-socials {
          gap: 20px;
          padding: 5px 10px;
          width: 90%;
        }
      }

      .corporateTwo-footer-upper-middle,
      .corporateTwo-footer-upper-right {
        width: 100%;
      }
    }

    .corporateTwo-footer-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 0px 20px 0px;
      gap: 10px;

      .corporateTwo-footer-bottom-left{
        text-align: center;
        h3{
          font-size: 1rem;
        }
      }

      .corporateTwo-footer-bottom-right {
        text-align: center;

        h4 {
          font-size: 1rem;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
