.banner{
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .big_circle{
        height: 100vh;
        aspect-ratio: 1 / 1;
        background-repeat: repeat;
        background-size: auto;       
        border-radius: 50%;
        background-color: var(--theme-yellow);
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .bc_inner{
           
            text-align: center;
            h6{
                font-family: title_regular;
                font-size: 26px;
                color: var(--theme-black);
                line-height: 38px;
                margin-bottom: 25px;
            }
            h2{
                font-size: 110px;
                font-family: title_regular;
                -webkit-text-stroke-color: var(--theme-white);
                -webkit-text-stroke-width: 1px;
                color: transparent;
                margin-bottom: 0px;
                line-height: 95px;
            }
            .typing_text{
                min-height: 100px;
            }
            h3{
                font-size: 110px;
                font-family: title_regular;
                color: var(--theme-white);
                line-height: 95px;
                margin-bottom: 0px;
                margin-top: 2px;
            }
            .theme_btn{
                margin-top: 22px;
            }
            .motion_img{
                position: absolute;
                right: -92px;
                bottom: 35px;
            }
        }
    }
}

