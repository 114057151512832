.tesimonial {
    .band {
      background: var(--theme-midnight-blue);
      padding: 50px 0px;
    }
    .testimonial_inner {
      box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
      background-color: var(--white-color);
      padding: 50px;
      margin-bottom: -30px;
      position: relative;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .star {
          display: flex;
          align-items: center;
          gap: 6px;
          span {
            color: var(--theme-orange);
            font-variation-settings: "FILL" 1;
            font-size: 35px;
          }
        }
      }
      .name_pic {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: end;
        .left {
          display: flex;
          flex-direction: column;
          text-align: end;
          gap: 8px;
        }
      }
      .right {
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          object-position: top;
          border: 3px solid var(--theme-orange);
        }
      }
      .say {
        margin-top: 12px !important;
        line-height: 32px;
        text-align: center;
      }
    }
  }
  