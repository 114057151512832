/* color variables start */
:root {
  --white-color: white;
  --text-black: #0d1820;
  --text-grey: #777777;
  --theme-orange: #f12711;
  --theme-blue: #0865f8;
  --theme-grey: #a9a9a9;
  --theme-midnight-blue: #0d1820;
  --theme-lightmidnight-blue: #1c2f3c;
  --theme-pg-bg: #edf2f6;
  --theme-green: #008000;
  --theme-crimson-red: #dc143c;
  /* #f5f5f9 */

  /* new colors may be remove */
  --theme-red: #ff0000;
  --theme-slate-grey: #708090;
  --theme-dark-blue: #003366;
  --theme-burgundy: #800020;
  --theme-teal: #008080;
  --theme-rich-purple: #800080;
}

/* color css start  */
.text_black {
  color: var(--text-black);
}

.text_grey {
  color: var(--text-grey);
}

.text_orange {
  color: var(--theme-orange);
}

.text_white {
  color: var(--white-color);
}
.text_blue {
  color: var(--theme-blue);
}
.text_teal {
  color: var(--theme-teal);
}
.click_icon_color,
.click_text {
  color: var(--theme-teal);
}
/* background colors */
.bg_midnightblue {
  background: var(--theme-midnight-blue);
}
.bg_orange {
  background: var(--theme-orange);
}
.bg_teal {
  background: var(--theme-teal);
}
/* color variables end*/
