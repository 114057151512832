.footer {
  .footer_top {
    margin-bottom: -90px;
    position: relative;
    z-index: 97;
  }
  .ft_inner {
    padding: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .footer_inner {
    padding-bottom: 70px;
    padding-top: 170px;
    background: var(--theme-midnight-blue);
  }
  .footer_links {
    h6 {
      margin-bottom: 30px;
      letter-spacing: 0.7px;
    }
    .fl_single {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 16px;
      color: var(--white-color);
      font-family: light;
      gap: 4px;
      -webkit-transition: 0.6s;
      transition: 0.6s;
      span {
        font-size: 20px;
      }
    }
    .fl_single:hover {
      -webkit-transition: 0.6s;
      transition: 0.6s;
      color: var(--theme-orange);
      letter-spacing: 1px;
    }
    .fl_inner {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .footer_social_media {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 20px;
    column-gap: 30px;
    img {
      width: 70px;
    }
  }
}
.copyright {
  background: var(--theme-lightmidnight-blue);
  padding: 30px 0px;
  // position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
}
