.form_field .special-label {
    display: none;
}
.mobile_number input {
    padding: 12px;
    border-radius: 0px;
    max-width: 250px;
}
.mobile_number {
  
    margin: 25px 0px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 40px;
    background-color: rgba(20, 33, 42, 0.83);
    border: 1px solid #21333f;
    .mobile_number_inner{
        display: flex;
        justify-content: center;
    }
}
.form-floating>label {
    font-size: 14px;
    color: var(--text-grey);
}
.form-control{
    border: none;
}
.form-control:focus{
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}