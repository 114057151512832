.bit_about {
    .ba_inner {
      position: relative;
      padding: 80px 0px;
      overflow: hidden;
    }
    .about_left {
      margin-right: 30px;
      grid-column-gap: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .img_container {
        position: relative;
        img {
          width: 100%;
          object-fit: cover;
          aspect-ratio: 1/1.5;
          border-radius: 5px;
          transition: 1.2s;
        }
        .img2 {
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: 1.2s;
          visibility: hidden;
        }
        .img4 {
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: 1.2s;
          visibility: hidden;
        }
      }
    }  
    .about_right {
      .main_title {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }
      .main_desc {
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
  
        display: -webkit-box;
  
        overflow: hidden;
        margin: 18px 0px 26px 0px;
      }
      .bottom {
        display: flex;
        align-items: center;
        gap: 30px;
        .call_btn {
          display: flex;
          text-decoration: none;
          align-items: center;
          gap: 6px;
          color: var(--theme-darkgrey);
          font-size: 23px;
          font-family: title_regular;
        }
        .material-symbols-outlined {
          color: var(--theme-yellow);
          font-size: 30x;
        }
      }
    }
    &.fivesec{
      .about_left {    
          .img1 {
            transition: 1.2s;
            visibility: hidden;
            opacity: 0;
          }
          .img3 {
            transition: 1.2s;
            visibility: hidden;
            opacity: 0;
          }
          .img2 {
            transition: 1.2s;
            visibility: visible;
            opacity: 1;
          }
          .img4 {
            transition: 1.2s;
            opacity: 1;
            visibility: visible;   
        }
      }
    }
  }
  