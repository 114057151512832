.admin-vendors {
    padding: 2rem;
    // background-color: #f8fafc;
    min-height: 100vh;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.5rem;
        
        h1 {
            font-size: 2rem;
            color: #1e293b;
            font-weight: 600;
            letter-spacing: -0.5px;
        }

        .add-button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.75rem 1.5rem;
            background-color: #3b82f6;
            color: white;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-weight: 500;
            font-size: 0.95rem;
            transition: all 0.2s ease;
            box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);

            &:hover {
                background-color: #2563eb;
                transform: translateY(-1px);
                box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
            }
        }
    }

    .vendor-form {
        max-width: 500px;
        margin: 0 auto;
        padding: 2rem;
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

        h2 {
            margin-bottom: 1.5rem;
            text-align: center;
            color: #1e293b;
            font-size: 1.5rem;
            font-weight: 600;
        }

        input {
            width: 100%;
            padding: 0.75rem 1rem;
            margin-bottom: 1rem;
            border: 1.5px solid #e2e8f0;
            border-radius: 8px;
            font-size: 0.95rem;
            color: #1e293b;
            transition: border-color 0.2s ease;

            &:focus {
                outline: none;
                border-color: #3b82f6;
                box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
            }

            &::placeholder {
                color: #94a3b8;
            }
        }

        .form-buttons {
            display: flex;
            gap: 1rem;
            justify-content: flex-end;
            margin-top: 1.5rem;

            button {
                padding: 0.75rem 1.5rem;
                border: none;
                border-radius: 8px;
                cursor: pointer;
                font-weight: 500;
                font-size: 0.95rem;
                transition: all 0.2s ease;

                &[type="submit"] {
                    background-color: #3b82f6;
                    color: white;

                    &:hover {
                        background-color: #2563eb;
                        transform: translateY(-1px);
                    }
                }

                &[type="button"] {
                    background-color: #f1f5f9;
                    color: #64748b;

                    &:hover {
                        background-color: #e2e8f0;
                        color: #475569;
                    }
                }
            }
        }
    }

    .vendors-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        gap: 1.5rem;
        padding: 0.5rem;

        .vendor-card {
            background-color: white;
            padding: 1.5rem;
            border-radius: 12px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
            border: 1px solid #e2e8f0;
            transition: all 0.2s ease;

            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 8px 12px rgba(0, 0, 0, 0.05);
            }

            h3 {
                margin-bottom: 1rem;
                color: #1e293b;
                font-size: 1.25rem;
                font-weight: 600;
            }

            p {
                margin-bottom: 0.75rem;
                color: #64748b;
                font-size: 0.95rem;
                line-height: 1.5;

                strong {
                    color: #475569;
                    font-weight: 500;
                }
            }

            .card-actions {
                display: flex;
                gap: 0.75rem;
                margin-top: 1.25rem;
                padding-top: 1rem;
                border-top: 1px solid #f1f5f9;

                button {
                    padding: 0.5rem;
                    border: none;
                    border-radius: 6px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s ease;

                    svg {
                        width: 1rem;
                        height: 1rem;
                    }

                    &.edit-button {
                        background-color: #e0f2fe;
                        color: #0284c7;

                        &:hover {
                            background-color: #bae6fd;
                            color: #0369a1;
                        }
                    }

                    &.delete-button {
                        background-color: #fee2e2;
                        color: #ef4444;

                        &:hover {
                            background-color: #fecaca;
                            color: #dc2626;
                        }
                    }
                }
            }
        }
    }

    .error {
        background-color: #fee2e2;
        color: #dc2626;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1.5rem;
        font-size: 0.95rem;
    }
}

// Add some responsive adjustments
@media (max-width: 640px) {
    .admin-vendors {
        padding: 1rem;

        .header {
            flex-direction: column;
            gap: 1rem;
            text-align: center;

            h1 {
                font-size: 1.75rem;
            }
        }

        .vendor-form {
            padding: 1.5rem;
        }

        .vendors-grid {
            grid-template-columns: 1fr;
        }
    }
}
