/* css for font poppins start */
/* font face start */
@font-face {
    font-family: extralight;
    src: url(../../../../../public/assets/font/poppins/Poppins-ExtraLight.ttf);
  }
  
  @font-face {
    font-family: light;
    src: url(../../../../../public/assets/font/poppins/Poppins-Light.ttf);
  }
  
  @font-face {
    font-family: regular;
    src: url(../../../../../public/assets/font/poppins/Poppins-Regular.ttf);
  }
  
  @font-face {
    font-family: medium;
    src: url(../../../../../public/assets/font/poppins/Poppins-Medium.ttf);
  }
  
  @font-face {
    font-family: semibold;
    src: url(../../../../../public/assets/font/poppins/Poppins-SemiBold.ttf);
  }
  
  @font-face {
    font-family: bold;
    src: url(../../../../../public/assets/font/poppins/Poppins-Bold.ttf);
  }
  
  @font-face {
    font-family: extrabold;
    src: url(../../../../../public/assets/font/poppins/Poppins-ExtraBold.ttf);
  }
  
  /* font face end */
  
  /* font family classes start  */
  .extralight {
    font-family: extralight;
  }
  
  .light {
    font-family: light;
  }
  
  .regular {
    font-family: regular;
  }
  
  .medium {
    font-family: medium;
  }
  
  .semibold {
    font-family: semibold;
  }
  
  .bold {
    font-family: bold;
  }
  
  .extrabold {
    font-family: extrabold;
  }
  body {
    font-family: regular !important;
  }
  /* font family classes start  */
  /* css for font poppins end*/