.customer-dashboard {
  display: flex;
  min-height: 100vh;
  background-color: #f5f6fa;

  &-sidebar {
    width: 20%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

    .sidebar-header {
      margin-bottom: 30px;

      h2 {
        color: #333;
        font-size: 24px;
      }
    }

    .sidebar-nav {
      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 12px 15px;
          margin: 5px 0;
          cursor: pointer;
          border-radius: 5px;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font-size: 20px;
          }

          &:hover {
            background-color: #f0f0f0;
          }

          &.active {
            background-color: var(--click-color);
            color: white;
          }
        }
      }
    }
  }

  &-content {
    width: 80%;
    padding: 20px;

    &-header {
      margin-bottom: 30px;

      h1 {
        color: #333;
        font-size: 28px;
      }
    }

    &-summary-info {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 30px;

      &-charts-line {
        width: 50%;
        display: flex;
        gap: 20px;

        .chart-container {
          flex: 1;
          background: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          aspect-ratio: 16/9;

          h3 {
            color: #666;
            font-size: 16px;
            margin-bottom: 15px;
            text-align: center;
          }

          canvas {
            flex: 1;
            width: 100% !important;
            height: auto !important;
          }
        }
      }

      &-charts-pie {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .chart-container {
          flex: 1;
          background: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          aspect-ratio: 16/9;

          h3 {
            color: #666;
            font-size: 16px;
            margin-bottom: 15px;
            text-align: center;
          }

          canvas {
            flex: 1;
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }

    &-orders-list {
      margin-top: 30px;
      background: white;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .customer-dashboard-orders-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h2 {
          color: #333;
          font-size: 20px;
        }

        button {
          background-color: var(--click-color);
          color: white;
          padding: 5px 10px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
        }
      }

      .customer-dashboard-orders-table {
        width: 100%;
        overflow-x: auto;

        table {
          width: 100%;
          border-collapse: collapse;

          th,
          td {
            padding: 12px 15px;
            text-align: left;
            border-bottom: 1px solid #eee;
          }

          th {
            background-color: #f8f9fa;
            color: #666;
            font-weight: 600;
            font-size: 14px;
          }

          td {
            color: #333;
            font-size: 14px;
          }

          tbody tr {
            &:hover {
              background-color: #f8f9fa;
            }
          }

          .status-badge {
            padding: 6px 12px;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;

            &.delivered {
              background-color: #e1f7e1;
              color: #2ecc71;
            }

            &.processing {
              background-color: #fff3cd;
              color: #ffa502;
            }

            &.cancelled {
              background-color: #ffe4e4;
              color: #e74c3c;
            }

            &.in.transit {
              background-color: #e1f0ff;
              color: #3498db;
            }
          }

          .details-button {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 6px 12px;
            border: none;
            background-color: var(--click-color);
            color: white;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            .span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.customer-dashboard-content {
  padding: 2rem;
  background: #f8f9fa;

  .dashboard-header {
    margin-bottom: 2rem;

    h1 {
      font-size: 1.8rem;
      color: #2d3436;
      margin-bottom: 0.5rem;
    }

    .text-muted {
      color: #636e72;
    }
  }

  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;

    .stat-card {
      background: white;
      border-radius: 12px;
      padding: 1.5rem;
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
      transition: transform 0.2s;

      &:hover {
        transform: translateY(-2px);
      }

      .stat-icon {
        background: #f1f3f9;
        padding: 12px;
        border-radius: 10px;

        .material-symbols-outlined {
          font-size: 24px;
          color: var(--click-color);
        }
      }

      .stat-details {
        flex: 1;

        .stat-label {
          color: #636e72;
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
        }

        .stat-value {
          font-size: 1.5rem;
          font-weight: 600;
          color: #2d3436;
          margin-bottom: 0.5rem;
        }

        .stat-change {
          font-size: 0.8rem;

          &.positive {
            color: #00b894;
          }
          &.negative {
            color: #ff7675;
          }
          &.neutral {
            color: #636e72;
          }
        }
      }
    }
  }

  .dashboard-charts {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
    margin: 20px 0;

    .chart-wrapper {
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .chart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h2 {
          margin: 0;
          font-size: 18px;
        }

        .time-filter {
          padding: 5px 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
          background-color: white;
          color: #333;
        }
      }

      // Set fixed heights for charts
      &.spending-chart {
        height: 400px;
        canvas {
          height: 300px !important; // Fixed height for line chart
        }
      }

      &.category-chart {
        height: 400px;
        canvas {
          height: 300px !important; // Fixed height for pie chart
        }
      }
    }
  }

  .recent-orders {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      h2 {
        font-size: 1.2rem;
        color: #2d3436;
      }

      .view-all-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border: none;
        background: var(--click-color);
        color: white;
        border-radius: 6px;
        cursor: pointer;
        transition: background 0.2s;
      }
    }

    .orders-table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 1rem;
        text-align: left;
        border-bottom: 1px solid #dfe6e9;
      }

      th {
        color: #636e72;
        font-weight: 500;
        font-size: 0.9rem;
      }

      .order-id {
        color: #4834d4;
        font-weight: 500;
      }

      .status-pill {
        padding: 0.25rem 0.75rem;
        border-radius: 20px;
        font-size: 0.85rem;
        font-weight: 500;

        &.delivered {
          background: #d8f3dc;
          color: #2d6a4f;
        }
        &.processing {
          background: #fff3bf;
          color: #937100;
        }
        &.in-transit {
          background: #dbe4ff;
          color: #364fc7;
        }
        &.cancelled {
          background: #ffe3e3;
          color: #c92a2a;
        }
      }

      .action-btn {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        padding: 0.5rem 1rem;
        border: 1px solid #dfe6e9;
        background: white;
        color: #636e72;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background: #f8f9fa;
          color: #4834d4;
          border-color: #4834d4;
        }
      }
    }
  }
}

.customer-dashboard-current-order-info {
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 2rem;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 1.75rem;
      font-weight: 600;
      color: #1e293b;
    }
  }

  &-metrics-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &-metrics {
    .metric-card {
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 1.5rem;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      gap: 1rem;
      border: 1px solid var(--click-color);

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      .metric-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(37, 99, 235, 0.1);
        border-radius: 50%;
        width: 48px;
        height: 48px;

        .material-symbols-outlined {
          color: #2563eb;
          font-size: 24px;
        }
      }

      .metric-details {
        .metric-label {
          color: #64748b;
          font-size: 0.875rem;
          margin-bottom: 0.25rem;
        }

        .metric-value {
          font-size: 1.5rem;
          font-weight: 600;
          color: #1e293b;
        }
      }
    }
  }

  &-orders-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }

  &-order-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: all 0.3s ease;
    border: 1px solid var(--click-color);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &-header {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #e2e8f0;

      h2 {
        font-size: 1.125rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 0.5rem;
      }
    }

    &-body {
      .text-muted {
        color: #64748b;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-view-all-btn-container {
  }

  &-view-all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: var(--click-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;

    .material-symbols-outlined {
      font-size: 20px;
    }
  }
}

@media (max-width: 768px) {
  .customer-dashboard-current-order-info {
    padding: 1rem;

    &-header h2 {
      font-size: 1.5rem;
    }

    &-orders-container {
      grid-template-columns: 1fr;
    }
  }
}
