.user-details {
  min-height: 100vh;
  background-color: var(--page-bg);

  .user-details-container {
    padding: 20px;

    .user-details-layout {
      display: flex;
      gap: 25px;
      .profile-card {
        width: 25%;
        height: 80vh;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0 40px 0;
        gap: 20px;
        position: fixed;
        .profile-photo {
          border-radius: 100%;
          width: 150px;
          height: 150px;
          background-color: #d8d3d3;
          position: relative;
          img {
            width: 150px;
            height: 150px;
            border-radius: 100%;
            padding: 8px;
            background-color: rgb(164, 161, 161);
          }
          .online-indicator {
            position: absolute;
            bottom: 10px;
            right: 15px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            .status-indicator {
              // border: 2px solid;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              margin: 1px;
            }
            .online {
              background-color: #22c55e; /* Green for online */
            }

            .offline {
              background-color: #e74747; /* Red for offline */
            }
          }
        }
        .profile-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h2 {
            font-size: 22px;
            margin-bottom: 15px;
          }
          .contact {
            font-size: 16px;
            margin-top: 4px;
            color: #6b7280;
          }
        }
      }
      .profile-detail {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-left: 28%;
        padding-bottom: 20px;
        .pd-cards {
          width: 100%;
          padding: 20px;
          align-items: center;
          border-radius: 10px;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          background: white;
          overflow: visible;

          .pd-info {
            display: flex;
            flex-direction: column;
            justify-content: start;
            gap: 20px;
            overflow: visible;
            .info-heading {
              display: flex;
              justify-content: space-between;
              gap: 80px;
              width: 60%;
              padding-left: 5px;
              div {
                text-align: left;
                label {
                  font-size: 12px;
                  font-weight: blod;
                  color: #868686;
                }
                p {
                  font-size: 15px;
                  font-weight: 400;
                }
              }
            }
            .info-detail {
              display: flex;
              justify-content: space-between;
              // padding: 20px;
              // background-color: #f0f5ff;
              border-radius: 8px;
              .status {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                background-color: #f0f5ff;
                padding: 15px;
                border-radius: 10px;

                h6 {
                  font-size: 14px;
                  font-weight: bold;
                }
              }
              .current-role {
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 15px;
                background-color: #f0f5ff;
                border-radius: 10px;
                // border: 2px solid;
                h6 {
                  font-size: 14px;
                  font-weight: bold;
                }
                p {
                  font-size: 16px;
                  color: #3f5e98;
                }
              }
              .current-mode {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                padding: 15px;
                background-color: #f0f5ff;
                border-radius: 10px;
                // border: 2px solid;
                .online-status {
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
                }
                p {
                  font-size: 16px;
                  color: #00a300;
                  padding: 0 0 0 4px;
                }
              }
            }
          }
          .pd-heading {
            display: flex;
            align-items: center;
            padding: 0 7px;
            h2 {
              font-size: 22px;
              color: #3f5e98;
            }
          }
          .pd-data {
            padding: 15px 0 0 8px;
            // border: 2px solid;
            display: flex;
            justify-content: left;
            flex-wrap: wrap;
            column-gap: 54px;
            row-gap: 30px;
            margin-top: 15px;

            .pd-data-element {
              display: flex;
              align-items: center;
              // border: 2px solid;
              gap: 10px;

              .pd-icon {
                flex: 1;
                img {
                  height: 32px;
                  width: auto;
                }
              }
              .pd-data-value {
                display: flex;
                flex-direction: column;
                gap: 2px;
                h6 {
                  font-size: 12px;
                  font-weight: bold;
                  line-height: normal;
                  margin-top: 0;
                  text-transform: capitalize;
                  color: #888;
                }
                p {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
  .animate-slide-in {
    animation: UserSlideIn 1s ease-out forwards;
  }

  @keyframes UserSlideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.theme_radio_container {
  // border: 1px solid var(--theme-grey);
  display: flex;
  // flex-wrap: wrap;
  gap: 10px;
  // padding: 4px 0 4px 0;
  .radio_single {
    .label_inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3px;
      width: 100%;

      .info_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: visible;

        .material-symbols-outlined {
          font-size: 16px;
          cursor: pointer;
        }

        .info_icon_inner {
          position: absolute;
          line-height: normal;
          background: #e6e6e6;
          z-index: 1000;
          font-size: 13px;
          padding: 7px;
          border-radius: 10px;
          width: 180px;
          right: 0;
          transition: 0.3s;
          opacity: 0;
          top: 18px;
          // color: var(--light-black);
          color: black;
          font-weight: 500;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
          .text_red {
            color: #fa6262;
          }
          .text_green {
            color: #00a300;
          }
          b {
            font-weight: 500;
          }
        }

        &:hover {
          .info_icon_inner {
            transition: 0.3s;
            opacity: 1;
          }
        }
      }
    }
  }

  // input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  // input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: flex;
    gap: 6px;
    cursor: pointer;
    padding: 6px 10px;
    border: 1px solid #498fff;
    color: var(--text-black);
    border-radius: 30px;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
    background-color: white;
    justify-content: center;
  }

  // input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {
    background-color: #3f5e98;
    color: var(--white-color);
    display: flex;
    justify-content: center;
  }
  // input[type="checkbox"] + label::before,
  input[type="radio"] + label::before {
    content: "\002B";
    display: inline-block;
    margin-right: 5px;
    font-size: 14px;
  }
  // input[type="checkbox"]:checked + label::before,
  input[type="radio"]:checked + label::before {
    content: "\2713"; /* Check mark icon */
    display: inline-block;
    margin-right: 5px;
    font-size: 14px;
  }
}
