.a_bit_about {
    h2 {
      margin-top: 16px;
      margin-bottom: 14px;
    }
    .about_left {
      .ac_more {
        margin-top: 35px;
        padding-left: 50px;
        position: relative;
        .icon {
          img {
            position: absolute;
            left: 0;
            right: 0;
          }
        }
        p {
          max-width: 385px;
        }
      }
    }
  
    .about_right {
      img {
        width: 100%;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
      }
      .exp {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        background-color: var(--theme-blue);
        color: var(--white-color);
        width: 250px;
        border-radius: 50%;
        text-align: center;
        height: 250px;
        bottom: 90px;
  
        p {
          color: var(--white-color);
          position: absolute;
          right: 0;
          left: 0;
          margin: auto;
          top: 50%;
          transform: translateY(-50%);
          font-size: 16px;
          font-weight: 400;
          width: 200px;
          height: 200px;
          border: 1px dashed var(--white-color);
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            display: block;
            font-size: 80px;
            font-family: bold;
            line-height: 1.1;
          }
        }
      }
    }
  }
  .rotate_circle {
    animation: animName 20s linear infinite;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: transparent;
    width: 250px;
    border-radius: 50%;
    height: 250px;
    bottom: 0px;
    border: 3px dashed var(--white-color);
  }
  @keyframes animName {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  