
.curve_divider{
    position: absolute;
    left: 0;
    z-index: 9;
    }
    .curve_divider.curve_bottom{ 
       bottom: 0;
        }
    .curve_divider img {
        width: 100%;
        height: auto;
    }
    .curve_divider.curve_top {
        top: 0;
    }