
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a{
    color: inherit;
}

a:active{
    color: inherit;
}

a:visited{
    color: inherit;
}

h1{
    font-family: "Poppins", sans-serif;
}

h2{
    font-family: "Poppins", sans-serif;
}

p{
    font-family: "Roboto" , sans-serif;
    line-height: 27px;
}