.feature_sect {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    background-attachment: fixed;
    h2 {
      margin-top: 12px;
      margin-bottom: 10px;
    }
    ul.list {
      padding-left: 0;
      margin-top: 30px;
      margin-bottom: 0;
      li {
        color: var(--white-color);
        list-style-type: none;
        display: flex;
        gap: 5px;
        margin-bottom: 10px;
        align-items: center;
        span {
          color: var(--theme-orange);
          font-size: 20px;
        }
      }
    }
    .feature_single {
      padding: 25px;
      position: relative;
      z-index: 1;
      background-color: rgba(20, 33, 42, 0.83);
      border: 1px solid #21333f;
  
      h3 {
        font-size: 20px;
        font-family: semibold;
        margin-bottom: 8px;
        transition: 0.6s;
        color: var(--white-color);
        line-height: 1.5;
        text-transform: capitalize;
        margin-top: 10px;
      }
      p {
        -webkit-transition: 0.6s;
        transition: 0.6s;
        color: var(--white-color);
        line-height: 26px;
        font-family: regular;
        opacity: 0.9;
        font-size: 15px;
      }
    }
    .feature_single:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 00%;
      background: #ffffff;
      border-radius: 0;
      -webkit-transition: 0.6s;
      transition: 0.6s;
    }
    .feature_single:hover:before {
      height: 100%;
     
    }
    .feature_single:hover{
      h3 {
          -webkit-transition: 0.6s;
          transition: 0.6s;
          color: var(--theme-midnight-blue);
        }
        p {
          -webkit-transition: 0.6s;
          transition: 0.6s;
          color: var(--theme-midnight-blue);
        }
    }
  }
  .feature_sect:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.77;
  }
  