.pg_contact_us {
  .pg_contact_us_popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .pg_contact_us_popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 400px;
    width: 90%;
  }
  
  .pg_contact_us_close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .pg_contact_us_selected-image-wrapper {
    position: relative;
    display: inline-block;
    width: fit-content;
  }
  
  .pg_contact_us_selected-image-preview {
    position: relative;
    display: inline-block;
  }

  .pg_contact_us_selected-image-preview img {
    max-width: 100%;
    max-height: 50vh;
    border-radius: 8px;
    margin-bottom: 20px;
  }
.react-tel-input .country-list .country-name{
  color: var(--grey-dark);
}
.pg_contact_us_uploading-gif-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-section-uploading-gif {
  width: 100%; /* Adjust the size for better visibility */
  height: 100%;
  object-fit: contain; /* Ensure the GIF scales properly */
  z-index: 9999;
}

.loc_em_ph_inner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #ddd;
  margin: 40px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  border-radius: 20px;
}

.lep_single {
  flex: 1;
  max-width: 250px;
  height: 80%;
  margin: 10px;
  text-align: center;
}

.lep_single .icon_div {
  background: var(--click-color);
  display: inline-block;
  border-radius: 50%;
  padding: 20px;
  height: 80px;
  width: 80px;
}

.lep_single_address {
  text-transform: capitalize;
  letter-spacing: 1px;
}

.icon_div span {
  color: #ffffff;
  padding: 3px;
  font-size: 35px;
}

.lep_single h4 {
  font-size: 26px;
  margin: 10px 0px;
}

.lep_single h6 {
  font-weight: 500;
}

section.form_sec {
  margin: 80px 0px;
  display: flex;
  flex-wrap: wrap;
}

.map_heading {
  margin: 0px auto 20px auto;
  font-weight: 600;
  font-size: 23px;
  position: relative;
  color: var(--click-color);
}

.map_heading::after {
  content: "";
  position: absolute;
  border-radius: 10px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 85px;
  height: 5px;
  background-color: #fff;
}

.map_top_heading {
  margin: 50px auto 30px auto;
  font-weight: 600;
  font-size: 23px;
  position: relative;
  color: var(--subheading-color);
}

.map_top_heading::after {
  content: "";
  position: absolute;
  border-radius: 10px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 85px;
  height: 5px;
  background-color: var(--subheading-color);
}

.contact-section {
  padding: 50px 0;
}

.contact-container {
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: white;
  min-height: 70vh;
}

.contact-image {
  flex: 1;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 10px;
  object-fit: contain;
}

.pg_contact_us_img_and_icon_div {
  position: relative;
}

.pg_contact_us_img_div {
  display: block;
  width: 100%;
  height: auto;
}

.pg_contact_us_img_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 5px;
  font-size: 24px;
  cursor: pointer;
}

.contact-image img {
  border-radius: 20px 0 0 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-form {
  flex: 1;
  padding: 50px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-form h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: var(--click-color);
}

.contact-form-fixed-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-form p {
  margin: 10px 0;
  font-size: 16px;
  color: #9b9b9b;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #ddd;
  width: 100%;
}

.cat-dropdown-contact-contact-form{
  padding: 15px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #ddd;
  width: 100%;
}

/* Media Queries for Responsiveness */

@media only screen and (max-width: 1440px) {
  .contact-section {
    margin: 0 20px;
  }

  .contact-container {
    padding: 0 10px;
  }

  .contact-form {
    padding: 30px;
  }

  .contact-form h2 {
    font-size: 28px;
  }

  .contact-form p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .pg_contact_us .banner img {
    height: 220px;
    margin-top: 100px;
  }

  .loc_em_ph_inner {
    /* display: grid;
    justify-content: center;
    padding: 15px;
    grid-template-columns: 1fr;
    gap: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .icon_div {
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon_div span {
    font-size: 20px;
  }

  .contact-container {
    margin: 0 15px;
    flex-direction: column;
  }

  .contact-image {
    display: none;
  }

  .contact-form {
    padding: 20px;
    color: var(--black-color);
  }

  .contact-form h2 {
    font-size: 24px;
  }

  .contact-form-fixed-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-form p {
    font-size: 14px;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    font-size: 14px;
  }

  .lep_single {
    margin: 5px;
  }

  .lep_single h4 {
    font-size: 20px;
  }

  .lep_single h6 {
    font-size: 16px;
  }

  .icon_div span {
    font-size: 30px;
  }
}
}