.happyerp {
  .pg_header {
    .first_row {
      display: flex;
      align-items: center;
      gap: 22px;
      justify-content: space-between;
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
    }
    .second_row {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        gap: 15px;
        align-items: center;
      }
      .right {
        display: flex;
        gap: 15px;
        align-items: center;
      }
    }
    .datefilter {
      input {
        padding: 5px 10px;
        border: none;
        margin: 0px;
        border: 1px solid var(--theme-blue);
        max-width: 135px;
        font-size: 14px;
        font-family: regular;
      }
      input:focus-visible {
        outline: none !important;
        border-radius: 0px;
        border-color: var(--theme-midnight-blue);
      }
      span {
        font-size: 22px;
        position: absolute;
        top: 49%;
        transform: translateY(-50%);
        right: 5px;
        color: var(--theme-blue);
        line-height: 0px;
      }
    }
    .button_filter {
      display: flex;
      align-items: center;
      border: 1px solid var(--theme-blue);
      background: var(--white-color);
      .bf_single {
        font-size: 14px;
        font-family: "regular";
        padding: 5px 15px;
        cursor: pointer;
        color: var(--text-grey);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .bf_single.active {
        background: var(--theme-blue);
        color: white;
      }
    }
  }

  .dashboard {
    .dashboard_inner {
      .congrats {
        display: flex;
        align-items: center;
        .left {
          width: 70%;
          padding: 22px;
        }
        .right {
          width: 30%;
          img {
            width: 100%;
            width: 100%;
            margin-bottom: -5px;
            margin-top: -28px;
          }
        }
      }
      .cg_1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 22px;
        grid-row-gap: 22px;
        .cd1_inner {
          display: flex;
          align-items: center;
          gap: 10px;
          .svg_icon {
            width: fit-content;
            img {
              position: absolute;
              top: 50%;
              right: 0;
              width: 32px;
              left: 4px;
              margin: auto;
              transform: translateY(-50%);
            }
          }
          .content {
            h6 {
              font-size: 14px;
              font-family: "regular";
              color: var(--text-grey);
            }
            h5 {
              font-size: 24px;
              font-family: "semibold";
              color: var(--text-black);
              margin-top: 5px;
            }
          }
        }
        .trending {
          position: absolute;
          bottom: 5px;
          right: 5px;
          .inner {
            display: flex;
            font-size: 12px;
            font-family: "medium";
            gap: 4px;
            align-items: center;
            span {
              font-size: 19px;
              line-height: 0px;
            }
            &.up {
              color: var(--theme-green);
            }
            &.down {
              color: var(--theme-crimson-red);
            }
          }
          p {
            font-size: 10px;
            color: var(--text-grey);
            margin-top: -4px;
          }
        }
      }
      .two_graph {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 22px;
      }
    }
  }
  /* Adjust the width of the bars */
  .recharts-bar-rectangle {
    width: 10px; /* Change to your desired width */
  }

  /* Remove shadow when hovering over bars */
  .recharts-bar-rectangle:hover {
    box-shadow: none !important;
  }
}
