.offer_banner {
    box-shadow: 0 2px 28px 0 #00000017;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    img{
        width: 100%;
        border-radius: 8px;
        aspect-ratio: 3 / 1;
        object-fit: cover;
        object-position: center;
    }
}