.admin-panel-sidebar {
    // height: 100vh;
    background: #ffffff;
    color: #1a1a1a;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;
    
    &.expanded {
        width: 250px;
    }
    
    &.collapsed {
        width: 88px;
    }

    .toggle-button {
        position: absolute;
        top: 20px;
        right: -15px;
        background: #ffffff;
        border: none;
        color: #5f6368;
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.2s;
        z-index: 10;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);

        &:hover {
            background-color: #f1f3f4;
            color: var(--click-color);
        }

        .material-symbols-outlined {
            font-size: 20px;
        }
    }

    .sidebar-header {
        padding: 16px 24px;
        display: flex;
        align-items: center;
        height: 64px;
        border-bottom: 1px solid #f1f3f4;

        .logo {
            font-size: 20px;
            font-weight: 500;
            margin: 0;
            color: #202124;
            font-family: 'Google Sans', sans-serif;
        }
    }

    .admin-panel-sidebar-menu {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .admin-panel-menu-item {
            display: flex;
            align-items: center;
            padding: 0 24px;
            height: 48px;
            color: #5f6368;
            text-decoration: none;
            transition: all 0.2s;
            position: relative;
            margin: 0;
            font-weight: 700;
            
            &:hover {
                background-color: #f2f1f4;
                color: var(--click-color);

                .tooltip {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.active {
                background-color: #f3e8fe;
                color: var(--click-color);
                
                &:hover {
                    background-color: #f3e8fe;
                }

                .icon {
                    color: var(--click-color);
                }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                
                .material-symbols-outlined {
                    font-size: 24px;
                }
            }

            .label {
                font-size: 14px;
                font-weight: 500;
                margin-left: 12px;
                font-family: 'Google Sans', sans-serif;
                white-space: nowrap;
            }

            .tooltip {
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                background: #333333;
                color: white;
                padding: 8px 12px;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.2s, visibility 0.2s;
                margin-left: 8px;
                z-index: 1000;
                box-shadow: 0 2px 4px rgba(0,0,0,0.2);

                &::before {
                    content: '';
                    position: absolute;
                    left: -6px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-style: solid;
                    border-width: 6px 6px 6px 0;
                    border-color: transparent #333333 transparent transparent;
                }
            }
        }
    }
}
