.admin-home {
    padding: 24px;
    
    h1 {
        margin-bottom: 32px;
        color: #333;
    }

    .admin-home-section {
        border-radius: 8px;
        padding: 20px 0;
        margin:20px 0
    }

    .admin-home-cards-section {
        display: flex;
        gap: 24px;
        margin-bottom: 10px;
        width: 100%;
        align-items: center;

        .admin-home-card {
            flex: 1;
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            width: 30%;

            .admin-home-card-header {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 20px;

                h2 {
                    font-size: 1.2rem;
                    color: #555;
                }
            }

            .admin-home-card-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                h3 {
                    font-size: 1.5rem;
                    font-weight: 700;
                    color: #333;
                    letter-spacing: 1px;
                }
            }
        }


    }

    .admin-home-charts-section {
        display: flex;
        gap: 24px;
        margin-bottom: 32px;
        width: 100%;
        
        .chart-container {
            flex: 1;
            background: white;
            border-radius: 8px;
            padding: 20px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            
            h2 {
                margin-bottom: 16px;
                color: #555;
                font-size: 1.2rem;
            }

            .chart-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
            }

            .chart-wrapper {
                height: 300px;
                width: 100%;
            }
        }
    }

    .admin-home-info-section {
        display: flex;
        gap: 24px;
        margin: 32px 0;

        .recent-orders, .vendor-stats {
            flex: 1;
            background: white;
            border-radius: 8px;
            padding: 20px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }

        .section-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .view-all {
                padding: 6px 12px;
                border: none;
                background: #f0f0f0;
                border-radius: 4px;
                cursor: pointer;
                font-size: 0.9rem;
                color: #666;
                transition: background 0.2s;

                &:hover {
                    background: #e0e0e0;
                }
            }
        }

        .orders-list {
            .order-item {
                padding: 12px 0;
                border-bottom: 1px solid #eee;

                &:last-child {
                    border-bottom: none;
                }

                .order-main {
                    display: flex;
                    gap: 12px;
                    margin-bottom: 6px;

                    .order-id {
                        font-weight: 600;
                        color: #333;
                    }

                    .customer {
                        color: #666;
                    }
                }

                .order-details {
                    display: flex;
                    gap: 12px;
                    font-size: 0.9rem;

                    .date {
                        color: #888;
                    }

                    .status {
                        padding: 2px 8px;
                        border-radius: 12px;
                        font-size: 0.8rem;

                        &.completed {
                            background: #e6f4ea;
                            color: #1e8e3e;
                        }

                        &.processing {
                            background: #fef7e0;
                            color: #b06000;
                        }

                        &.pending {
                            background: #f1f3f4;
                            color: #666;
                        }
                    }

                    .amount {
                        font-weight: 600;
                        color: #333;
                    }
                }
            }
        }

        .vendor-stats {
            .vendors-list {
                .vendor-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 0;
                    border-bottom: 1px solid #eee;

                    &:last-child {
                        border-bottom: none;
                    }

                    .vendor-name {
                        color: #333;
                        font-weight: 500;
                    }

                    .order-count {
                        color: #666;
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    .admin-home-info-section {
        .recent-orders {
            .section-header {
                padding-bottom: 12px;
                border-bottom: 1px solid #eee;

                .view-all {
                    padding: 4px 8px;
                    font-size: 0.8rem;
                }
            }

            .orders-list {
                .order-item {
                    padding: 8px 0;
                    border-bottom: 1px solid #eee;

                    &:last-child {
                        border-bottom: none;
                    }

                    .order-main {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin-bottom: 4px;

                        .order-id {
                            font-size: 0.85rem;
                            color: #444;
                        }

                        .customer {
                            font-size: 0.85rem;
                            color: #666;
                        }
                    }

                    .order-details {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        .date {
                            font-size: 0.8rem;
                            color: #888;
                        }

                        .status {
                            padding: 2px 6px;
                            border-radius: 4px;
                            font-size: 0.75rem;
                            font-weight: 500;

                            &.completed {
                                background: #e6f4ea;
                                color: #1e8e3e;
                            }

                            &.processing {
                                background: #fef7e0;
                                color: #b06000;
                            }

                            &.pending {
                                background: #f1f3f4;
                                color: #666;
                            }
                        }

                        .amount {
                            font-size: 0.85rem;
                            font-weight: 500;
                            color: #333;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}
