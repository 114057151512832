.home_services .services_card {
    margin-top: -90px;
    position: relative;
    z-index: 9;
  }
  .services_card {
    background-color: var(--white-color);
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    padding: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .sc_single {
      padding: 0px 15px;
      h2 {
        font-size: 25px;
        font-family: semibold;
        color: var(--text-black);
        margin-bottom: 10px;
      }
      p {
        font-size: 15px;
        font-family: regular;
        color: var(--text-grey);
        line-height: 1.8;
      }
      ul.list {
        padding-left: 0px;
       margin-top: 20px;
       margin-bottom: 30px;
        li {
          list-style-type: none;
          font-size: 17px;
          font-family: regular;
          color: var(--text-black);
          margin-bottom: 12px;
        }
      }
      .scs_inner.border_right{
          border-right: 1px dashed var(--theme-grey);
      }
  
    }
    .sc_single.with_top_img {
      img {
        margin-bottom: 30px;
      }
    }
    .sc_single.with_bottom_img {
      img {
        margin-top: 30px;
      }
    }
    .sc_single:first-child {
      padding-left: 0px;
    }
    .sc_single:last-child {
      padding-right: 0px;
    }
  }
  