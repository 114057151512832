/* .bcard {
    margin-top: 95px;
    padding: 15px;
    background: var(--white-color);
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    border-radius: 20px;
    position: relative;
    max-width: 520px;
    margin-right: auto;
    margin-left: auto;
}

.bcard_title {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--dark-blue);
    color: var(--white-color);
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px 0px 0px 0px;
}

.bcard_inner {
    margin-top: 40px;
}

.bcard_inner {
    margin-top: 40px;
}

.booking_for {
    padding: 20px;
    background: var(--white-color);
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    border-radius: 20px;
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.booking_for h4 {
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 11px;
}

.bci_fields {
    grid-column-gap: 14px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.form_field {
    margin-bottom: 14px;
    position: relative;
}

.field_icon {
    left: 12px;
    position: absolute;
    top: 10px;
}

.material-symbols-outlined {
    color: var(--blue-color);
}

.text-end {
    text-align: right !important;
}

@media (max-width: 767px) {
    .bcard {
        margin-top: 15px;

    }
}


@media (max-width: 575px) {
    .bci_fields {
        grid-column-gap: 14px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

input[type="time"] {
    position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    opacity: 0;

} */


.addbooking-bcard {
    margin: 0px auto 0px auto;
    max-width: 95%;
    padding: 15px;
    background: var(--white-color);
    /* box-shadow: 0px 0px 27px 0px; */
    border: 3px solid #dedcdc;
    border-radius: 20px;
    position: relative;
    /* max-width: 95%; */
    margin-right: auto;
    margin-left: auto;
    background-color: #f1efef;
    display: flex;
}


.addbooking_dr_details_upper {
    background-color: var(--white-color);
    width: 90%;
    /* height: 60px; */
    padding: 5px 10px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -28px;
}

.add_booking_doctor_parent {
    margin-top: 100px;
    background-color: #f1efef;
}

.addbooking_dr_data_parent {
    padding: 0px;
}

.addbooking_dr_img_parent {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addbooking_dr_img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 5px solid #cccccc;
}

.dr-details--lower {
    margin-top: -25px;
    /* display: none; */
}

.time_button_parent_div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--click-color);
    /* border: none; */
    /* border-radius: 6px; */
    color: var(--white-color);
    padding: 10px 35px;
    font-family: 'p_bold';
    letter-spacing: 1px;
    text-transform: capitalize;
}

.time_button_parent_div span {
    color: var(--click-color);
    background-color: aliceblue;
    padding: 10px;
    font-size: 20px;
    border-radius: 50%;
    font-weight: 700;
}

.custom-datepicker-input {
    /* width: 80%; */
    display: flex;
    align-items: center;
    margin: 20px 0px 0px 0px;
    /* justify-content: center; */

}

/* Styles for the carousel container */
.carousel-container {
    width: 100%;
    overflow-x: scroll;
    /* Enable horizontal scrolling */
    scroll-snap-type: x proximity;
    /* Enable horizontal scroll snapping */
    white-space: nowrap;
    margin-top: 20px;

    /* Hide the scrollbar */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 11 */
}

/* Hide the scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.carousel-container::-webkit-scrollbar {
    display: none;
}

/* Styles for the carousel */
.carousel {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    /* Adjust the gap between buttons as needed */
    scroll-snap-align: start;
    /* Snap to the start of each button */
    transition: transform 0.3s ease-in-out;
}


.addbooking_selected_date_div p {
    font-weight: 600;
    color: #797979;
    font-size: 17px;
    letter-spacing: 1px;
    padding: 8px;
}

.am_pm_parent_div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.am-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 0px;
}

.am-btn button {
    background: var(--click-color);
    border: none;
    font-size: 20px;
    color: var(--white-color);
    padding: 5px 25px;
    letter-spacing: 1px;
}

.pm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 50px 0px;
}

.pm-btn button {
    background: var(--click-color);
    border: none;
    font-size: 20px;
    color: var(--white-color);
    padding: 5px 25px;
    letter-spacing: 1px;
}

.addbooking_dr_details_upper h6 {
    margin-right: 10px;
    padding: 0px 20px 0px 10px;
}



.addbooking-ampm-button-container {
    margin: -30px 0px 25px 0px;
    text-align: center;
}

.half-hour-button {
    margin: 5px;
    border: 2px solid var(--click-color);
    background-color: #ffffff;
    border-radius: 6px;
    color: var(--click-color);
    padding: 8px 0px;
    font-family: 'p_bold';
    font-size: 14px;
    text-transform: capitalize;
    width: 115px;
}

.selectedTime {
    background-color: var(--click-color);
    margin: 5px;
    border-radius: 6px;
    color: var(--click-color);
    padding: 8px 0px;
    font-family: 'p_bold';
    text-transform: capitalize;
    width: 100px;
}

.selected {
    background-color: var(--pink);
    color: white;
    transition: .5s;
}

.addbooking-toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 40px 0px;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.add_booking_colleps {
    padding: 0px;
    margin-top: 55px;
    margin-bottom: 0px;
}

.add_booking_colleps_child {
    padding: 0px;
    border: 2px solid var(--pink);
    box-shadow: 3px 3px 10px 1px;
}

.addbooking_group_icon {
    background-color: rgb(242, 242, 242);
    padding: 9px;
    border-radius: 50%;

}

.addbooking_reward_icon {
    background-color: rgb(242, 242, 242);
    padding: 9px;
    border-radius: 50%;
}

.add_booking {
    margin-top: 0px;
    background-color: #f1efef;
}

.bcard_title {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--dark-blue);
    color: var(--white-color);
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px 0px 0px 0px;
}



.bcard_inner {
    /* margin-top: -120px; */
}

.booking_for {
    padding: 20px;
    background: var(--white-color);
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    border-radius: 20px;
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.booking_for h4 {
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 11px;
}

.form_field {
    margin-bottom: 14px;
    position: relative;
}

.field_icon {
    left: 12px;
    position: absolute;
    top: 10px;
}

.add-booking-days-button-parent {
    display: flex;
    justify-content: center;
}

/* Add your CSS file with the following styles */
.selectedDate {
    background-color: var(--pink);
    margin: 8px;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    width: 50px;
    transition: .5s;
}

.add-booking-days-button {
    margin: 8px;
    background-color: var(--click-color);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    width: 50px;
}

.text-end {
    text-align: center !important;
}

.final_doctor_booking_card_parent {
    display: grid;
    /* background-color: #0074d9; */
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding-bottom: 70px;
    justify-content: center;
    background-color: #f1efef;
}

.final_doctor_booking_card {
    display: grid;
    background-color: white;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    /* Change the second row height to "auto" */
    border-radius: 10px;
    padding: 10px 20px 5px 20px;
    height: 280px;
    margin-top: 20px;
}

.addbooking_booking_card_dr_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid var(--pink);
    object-fit: cover;
}

.hr-line {
    width: 100%;
    border: 1px solid #000;
    /* You can adjust the color and style as needed */
    grid-column: 1 / span 3;
    /* Span across all three columns */
}

.booking-collaps-heading {
    /* border-top: 1px solid var(--click-color); */
    color: var(--click-color);
    font-weight: 700;
    margin: 15px 0px -10px 15px;
}

.box-2 {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.box-3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-2 p {
    margin-left: 10px;
}

.box-4 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 2/3;
    grid-column: 1/-1;
    /* height: 5px; */
    margin-top: 0px;
    display: flex;
    align-items: center;
}

.box-5 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 3/4;
    grid-column: 1/3;
    /* margin-top: -100px; */
    display: flex;
    /* align-items: center; */
    justify-content: start;
}

.box-6 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 3/4;
    grid-column: 3/-1;
    /* margin-top: -100px; */
    display: flex;
    /* align-items: center; */
    justify-content: start;
}

.box-7 {
    justify-content: start;
    grid-row: 4/5;
    grid-column: 1/3;
    /* height: 10px; */
    /* margin-top: -20px; */
    display: flex;
    /* align-items: center; */
}

.box-8 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 4/5;
    grid-column: 2/4;
    /* margin-top: -20px; */
    display: flex;
    justify-content: end;
    /* align-items: center; */
}

.box-9 {
    grid-row: 5/6;
    grid-column: 1/-1;
    display: flex;
    /* justify-content: end; */
    align-items: center;
    /* margin-top: -15px; */
}

.box-10 {
    grid-row: 6/7;
    grid-column: 1/-1;
    display: flex;
    justify-content: end;
    align-items: center;
    /* margin-top: -15px; */
}

.box-11 {
    grid-row: 7/8;
    grid-column: 1/-1;
    display: flex;
    /* justify-content: end; */
    align-items: center;
    margin-top: -15px;
}

@media (max-width: 990px) {
    .addbooking-bcard {
        margin-top: 20px;
        display: inline-block;
    }

    .add_booking_colleps {
        display: block;
        padding: 0px;
        margin-top: 55px;
    }

    .add_booking_colleps_child {
        padding: 0px;
        border: 2px solid var(--pink);
        box-shadow: 3px 3px 10px 1px;
    }


    .dr-details--lower {
        margin-top: 0px;
    }

    .final_doctor_booking_card_parent {
        padding: 0px 0px 60px 0px;
        grid-template-columns: 1fr;
    }
}


@media (max-width: 575px) {

    .add_booking {
        margin-top: 0px;
    }


    .addbooking_dr_details_upper {
        bottom: -30px;
    }

    .add_booking_doctor_parent {
        margin-top: 15px;
    }

    .final_doctor_booking_card_parent {
        padding: 0px 0px 60px 0px;
        grid-template-columns: 1fr;
    }

}

input[type="time"] {
    position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    opacity: 0;

}